import { Swiper } from 'swiper';
export * from 'swiper/modules';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import './styles.scss';

Swiper.extendDefaults({
    lazyPreloadPrevNext: 2,
    loop: true,
    autoplay: {
        pauseOnMouseEnter: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
    },
});

export { Swiper };
export default Swiper;
