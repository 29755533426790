import 'slick-slider/slick/slick';
import 'slick-slider/slick/slick.scss';

interface SlickResponsiveOptions {
    infinite?: boolean;
    centerPadding?: string;
    dots?: boolean;
    autoplaySpeed?: number;
}

interface SlickSliderOptions {
    autoplay?: boolean;
    asNavFor?: Element | JQuery | string;
    speed?: number;
    mobile?: SlickResponsiveOptions;
    desktop?: SlickResponsiveOptions;
}

function slickSlider(selector: string, opts?: SlickSliderOptions): JQuery {
    return $(selector).slick({
        arrows: false,
        autoplay: opts?.autoplay ?? true,
        asNavFor: opts?.asNavFor,
        mobileFirst: true,
        pauseOnDotsHover: true,
        speed: opts?.speed ?? 500,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    autoplaySpeed: opts?.mobile?.autoplaySpeed ?? 3000,
                    centerMode: true,
                    centerPadding: opts?.mobile?.centerPadding ?? '0',
                    dots: opts?.mobile?.dots ?? false,
                    infinite: opts?.mobile?.infinite ?? false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    autoplaySpeed: opts?.desktop?.autoplaySpeed ?? 4000,
                    dots: opts?.desktop?.dots ?? true,
                    fade: true
                }
            }
        ]
    });
}

export default slickSlider;
